<template>
  <div>

    <b-card
        no-body
        class="mb-0"
    >
      <titulo-card-com-voltar
          v-if="dadosProposta"
          :voltar="'propostas'"
          :titulo="tituloProposta"
      />
      <div class="m-2 px-2">
        <b-row>
          <b-col
              cols="9"
              class="pb-2"
          >
            <div class="font-weight-bold">
              Proposta:
            </div>
            <div>{{ dadosProposta && dadosProposta.codigo_proposta }}</div>
          </b-col>
          <b-col
              cols="3"
              class="pb-2"
          >
            <div class="font-weight-bold">
              Status
            </div>
            <div>
              {{ dadosProposta && dadosProposta.status.descricao }}
            </div>
          </b-col>
          <b-col
              cols="4"
              class="pb-2"
          >
            <div class="font-weight-bold">
              Solicitado em:
            </div>
            <div>{{
                dadosProposta &&
                mascaraData(dadosProposta.data_solitacao)
              }}
            </div>
          </b-col>
          <b-col
              cols="3"
              class="pb-2"
          >
            <div class="font-weight-bold">
              Entrega:
            </div>
            <div>{{ dadosProposta && mascaraData(dadosProposta.data_entrega) }}</div>
          </b-col>
          <b-col
              cols="2"
          >
            <div class="font-weight-bold">
              Nome:
            </div>
            <div>{{ dadosProposta && dadosProposta.nome }}</div>
          </b-col>

          <b-col
              cols="4"
              class="mt-2"
          >
            <div class="font-weight-bold">
              Empresa:
            </div>
            <div>{{ dadosProposta && dadosProposta.empresa.nome }}</div>
          </b-col>
          <b-col
              cols="6"
              class="mt-2"
          >
            <div class="font-weight-bold">
              Responsavel:
            </div>
            <div>{{ dadosProposta && dadosProposta.responsavel.name }}</div>
          </b-col>
          <b-col
              cols="2"
              class="mt-2"
          >
            <div class="font-weight-bold">
              Tipo:
            </div>
            <div>{{ dadosProposta && dadosProposta.tipo_custo }}</div>
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col
              cols="6"
              v-if="possuiCliente"
          >
            <div class="font-weight-bold">
              Cliente:
            </div>
            <div class="font-size-9 d-block">{{ dadosProposta && dadosProposta.cliente.razao_social }}</div>
            <div class="font-size-7 d-block">{{ dadosProposta && dadosProposta.cliente.cnpj }}</div>
          </b-col>
          <b-col
              cols="6"
              v-else
          >
            <div class="font-weight-bold">
              Lead:
            </div>
            <div class="font-size-9 d-block">{{ dadosProposta && dadosProposta.lead.razao_social }}</div>
            <div class="font-size-7 d-block">{{ dadosProposta && dadosProposta.lead.cnpj }}</div>
          </b-col>
          <b-col
              cols="6"
              v-if="possuiParceiro"
          >
            <div class="font-weight-bold">
              Parceiro:
            </div>
            <div class="font-size-9 d-block">{{ dadosProposta && dadosProposta.parceiro.razao_social }}</div>
            <div class="font-size-7 d-block">{{ dadosProposta && dadosProposta.parceiro.cnpj }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              class="mt-2"
              v-if="dadosProposta && dadosProposta.disciplinas"
          >
            <div class="font-weight-bold">
              Disciplinas do projeto:
            </div>
            <div class="d-flex">
              <tag-color
                  v-for="(item, index) in dadosProposta.disciplinas"
                  :key="index"
                  v-b-tooltip.hover.top.v-primary
                  class="my-1 cursor "
                  :texto="item.abreviacao"
                  :title="item.nome"
                  :bg-color="item.bg_color"
              />
            </div>

          </b-col>
        </b-row>
        <b-row>

          <b-col cols="12" class="mt-2">
            <b-form-group
                label-for="subdisciplinas"
            >
              <b-list-group v-if="dadosProposta && dadosProposta.listaSubsSelecionadas">
                <div v-for="(itens, chave) in dadosProposta.listaSubsSelecionadas" :key="chave">
                  <b-list-group-item active v-if="itens.length > 0">
                    <div class="d-flex justify-content-between">
                      <div>
                        {{ chave }}
                        <b-badge
                            variant="dark"
                            pill
                            class="badge-round"
                        >
                          {{ itens.length }}
                        </b-badge>
                      </div>
                      <div class="font-weight-bold">{{ calcValorDisc(itens) }}</div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item v-for="sub in itens" :key="sub.uuid">
                    <div class="d-flex justify-content-between ">

                      <div class="font-size-7 d-flex align-items-center">
                        <div class="pr-1">{{ sub.quantidade }}</div>
                        <div> {{ sub.nome }}</div>
                      </div>
                      <div class="d-flex font-size-8 justify-content-between" style="width: 200px">
                        <div class="text-left text-gray "> {{ formatarParaMoedaBrasileira(sub.valor) }}</div>
                        <div class="text-left font-weight-bold">
                          {{ formatarParaMoedaBrasileira(sub.valor * sub.quantidade) }}
                        </div>


                      </div>
                    </div>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              class="mt-2"
          >
            <b-button
                variant="success"
                size="md"
                class="float-right"
                @click=""
            >
              Gerar PDF
            </b-button>
          </b-col>
        </b-row>
      </div>

    </b-card>

  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BImg,
  BListGroup,
  BListGroupItem,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'

import { formatarParaMoeda, formatarParaMoedaBrasileira, mascaraData } from '@/@core/utils/filter'
import store from '@/store'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import userStoreModule from '../propostaStoreModule'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'

export default {
  components: {
    BFormSpinbutton,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BBadge,
    TagColor,
    BFormInput,
    BButton,
    TituloCardComVoltar,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const ITEM_APP_STORE_MODULE_NAME = 'app-propostas'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    })

    return {
      mascaraData,
    }
  },
  mounted() {
    this.getItem()
  },
  data: () => ({
    dadosProposta: null,
  }),
  computed: {
    possuiCliente() {
      return this.dadosProposta && this.dadosProposta.cliente
    },
    possuiParceiro() {
      return this.dadosProposta && this.dadosProposta.parceiro
    },
    tituloProposta() {
      return this.dadosProposta && `Proposta ${this.dadosProposta.codigo_proposta}`
    }
  },
  methods: {
    formatarParaMoedaBrasileira,
    async getItem() {
      try {
        const response = await store.dispatch('app-propostas/show', router.currentRoute.params.id)
        this.dadosProposta = response
        this.dadosProposta.listaSubsSelecionadas = this.montarSubsParaEdicao(this.dadosProposta.subdisciplinas)

      } catch (error) {
        console.error('Erro ao obter item:', error)
      }
    },
    montarSubsParaEdicao(subs) {
      const subsSelecionadas = {}

      subs.forEach(sub => {
        const { disciplinas } = sub
        const disciplinaNome = disciplinas[0].nome

        sub.uuid = sub.id

        if (subsSelecionadas[disciplinaNome]) {
          subsSelecionadas[disciplinaNome].push(sub)
        } else {
          subsSelecionadas[disciplinaNome] = [sub]
        }
      })
      return subsSelecionadas
    },
    calcValorDisc(itens) {

      const total = itens.reduce((acc, item) => {
        if (!item.valor) return acc
        return acc + (Number(item.valor) * item.quantidade)
      }, 0)

      return formatarParaMoeda(total)
    },
  }
}
</script>
