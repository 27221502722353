import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise(resolve => {
        axios
          .get('/propostas', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, idItem) {
      return new Promise((resolve, reject) => {
        axios
          .get(`propostas/${idItem}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/propostas', itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios.put(`/propostas/${itemData.id}`, itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/propostas/${id}`)
          .then(() => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
